import React from "react";

/*Importing Wrapper for Home*/
import { FlexibleDiv } from "../../components/flexibleDiv/flexibleDiv.component";
import { Spacer } from "../../components/spacer/spacer.component";

/*importing Home sections*/
import Hero from "./Sections/Hero/hero.section";
// import WhyChooseUs from "./Sections/WhyChooseUs/whyChooseUs.section";
import InternWithUs from "./Sections/InternWithUs/internWithUs.section";
import Testimonials from "./Sections/Testimonials/testimonials.section";
import Partners from "./Sections/Partners/partners.section";
// import Clients from "./Sections/Clients/clients.section";
import ContactUs from "./Sections/ContactUs/contactUs.section";
// import OurProducts from "./Sections/OurProducts/ourProducts.section";
import AboutItechnology from "./Sections/AboutItechnology/aboutItechnology.section";
// import OurPortfolio from "./Sections/OurPortfolio/ourPortfolio.section";
import HireRealExpert from "./Sections/HireRealExpert/HireRealExpert.section";
import StartWithItechnology from "./Sections/StartWithItechnology/StartWithItechnology.section";
import HowItWorks from "./Sections/HowItWorks/HowItWorks.section";
import StartAProject from "./Sections/StartAProject/StartAProject.section";

const Home = () => {
  return (
    <FlexibleDiv flexDirection="column" paddingVertical="0">
      <Spacer size="small" />
      <Hero />
      <Spacer size="small" />
      <HireRealExpert />
      <Spacer size="small" />
      <AboutItechnology />
      <Spacer size="small" />
      <InternWithUs />
      <Spacer size="small" />
      <StartWithItechnology />
      <Spacer size="small" />
      <Partners />
      <Spacer size="small" />
      {/* <Clients />
      <Spacer size="small" /> */}
      {/* <OurPortfolio />
      <Spacer size="small" />
      <WhyChooseUs />
      <Spacer size="small" /> */}

      <Testimonials />
      <Spacer size="small" />
      <HowItWorks />
      <Spacer size="small" />

      {/* <OurProducts />
      <Spacer size="small" /> */}
      <StartAProject />
      <Spacer size="small" />
      <ContactUs />
    </FlexibleDiv>
  );
};

export default Home;
