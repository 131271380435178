import React from "react";
import Blurb from "../../../../components/blurb/blurb.component";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
import { ImageTextCard } from "../../../../components/ImageTextCard/imageTextCard.component";
// import BEOLogo from "../../../../assets/images/Blessed-Emoefe.svg";
import Google from "../../../../assets/images/googlelogo_color_272x92dp.png";
import Pluralsight from "../../../../assets/images/PS-horizontal-color-fill-blue-text.png";
import PIND from "../../../../assets/images/PIND.png";
import Streeet101 from "../../../../assets/images/street101.jpeg";
import { Spacer } from "../../../../components/spacer/spacer.component";
import { generateID } from "../../../../infrastructure/lib/generateID";
import { colors } from "../../../../infrastructure/theme/colors";

const Partners = () => {
  const data = [
    { image: Pluralsight, name: "Pluralsight" },
    { image: Streeet101, name: "Streeet101" },
    { image: Google, name: "Google" },
    { image: PIND, name: "PIND" },
    // { image: BEOLogo, name: "Projistry" },
    // { image: Google, name: "Google" },
    // { image: Embed, name: "Embed" },
    // { image: Chike, name: "Chike" },
  ];

  return (
    <FlexibleDiv
      boxedLayout
      paddingVertical="3rem"
      background={colors.bg.secondary}
    >
      <FlexibleDiv paddingVertical="0" flexDirection="column" flexWrap="wrap">
        <Blurb width="70%">
          <h5>Partners</h5>
          <h3>Partnered Institutions</h3>
        </Blurb>
        <Spacer size="small" />
        <FlexibleDiv paddingVertical="0">
          {data.map(({ image, name }) => (
            <ImageTextCard image={image} key={generateID(15)} />
          ))}
        </FlexibleDiv>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export default Partners;
