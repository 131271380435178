import styled from "styled-components";
import { colors } from "../../infrastructure/theme/colors";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";

export const ImageCardContainer = styled(FlexibleDiv)`
  width: auto;
  /* min-width: 16rem; */

  border-radius: 5px;
  /* border: 0.5px solid ${colors.bg.primary}; */
  transition: 0.3s;
  overflow: hidden;

  :hover {
    /* transition: 0.3s;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  }
  @media (max-width: 800px) {
    width: ${({ resWidth }) => (resWidth ? resWidth : "100%")};
  }
`;

export const ImageWrapper = styled(FlexibleDiv)`
  /* filter: invert(10%) sepia(100%) saturate(307%) hue-rotate(130deg)
    brightness(20%) contrast(1%); */
`;

export const LabelWrapper = styled(FlexibleDiv)`
  margin-top: 2rem;
`;
