import React from "react";
import Blurb from "../../../../components/blurb/blurb.component.jsx";
import { colors } from "../../../../infrastructure/theme/colors.js";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component.jsx";
import { Button } from "../../../../components/button/button.component";
// import {
//   HorizontalSpacer,
//   Spacer,
// } from "../../../../components/spacer/spacer.component.jsx";
import {
  StartWithItechnologyContainer,
  HeaderInfo,
  CardContainer,
  ActionCard,
  BulletPointWrapper,
  BulletPoints,
  Point,
  CardSummaryTextWrapper,
  CardSummaryText,
} from "./StartWithItechnology.styles";
import { HiBadgeCheck as CheckIcon } from "react-icons/hi";
import { generateID } from "../../../../infrastructure/lib/generateID.js";
import { Link } from "react-scroll";

const StartWithItechnology = () => {
  const BulletPointsData = [
    { points: "Gain power and prestige" },
    { points: "Attract powerful investors" },
    { points: "Make your competitors jealous" },
  ];
  return (
    <StartWithItechnologyContainer
      boxedLayout
      id="WhyUs"
      // background={colors.bg.secondary}
    >
      <HeaderInfo>
        <Blurb textAlign="left" alignItems="flex-start">
          <h5>Start with Itechnology Terrain</h5>
          <h3>Make your brand look iconic, now!</h3>
          <p>
            While other agencies will gladly take your money, they won’t
            fundamentally move the needle. At ITTerrain we build brands the
            strategic way to get better results.
          </p>
        </Blurb>
      </HeaderInfo>
      <CardContainer

      // border={`1px solid teal`}
      // borderRadius={"15px"}
      >
        <ActionCard>
          <BulletPointWrapper>
            {BulletPointsData.map(({ points }) => (
              <BulletPoints key={generateID(15)}>
                <CheckIcon size="2rem" color={colors.white} />
                {/* <HorizontalSpacer size={"small"} /> */}
                <Point>{points}</Point>
              </BulletPoints>
            ))}
          </BulletPointWrapper>
          <CardSummaryTextWrapper>
            <CardSummaryText>
              Stop being ashamed of how your brand looks and reach your true
              potential with Itechnology Terrain.
            </CardSummaryText>
          </CardSummaryTextWrapper>
          <FlexibleDiv
            justifyContent="center"
            paddingVertical="0"
            alignItems="center"
          >
            {/* <Button
              btnText="Apply for Internship"
              background={colors.transparent}
              border={`2px solid ${colors.primary}`}
              btnColor={colors.primary}
            />
       */}
        <Link
            activeClass="active"
            style={{ width: "100%" }}
            to="StartAProject"
            spy={true}
            smooth={true}
            offset={-80}
            duration={500}
          >

            <Button
              btnText="Start a project"
              background={colors.white}
              border={`2px solid ${colors.white}`}
              btnColor={colors.primary}
              width="100%"
            />

          </Link>
          </FlexibleDiv>
        </ActionCard>
      </CardContainer>
    </StartWithItechnologyContainer>
  );
};

export default StartWithItechnology;
