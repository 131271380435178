import React from "react";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";

import Blurb from "../../../../components/blurb/blurb.component";
// import { colors } from "../../../../infrastructure/theme/colors";
// import Slider from "../../../../components/Slider";
// import GamifiedLearning from "../../../../assets/images/h1.png";
// import Image2 from "../../../../assets/images/h3.png";
// import Image3 from "../../../../assets/images/h5.png";
import Image1 from "../../../../assets/images/h2.png";
import { Text } from "../../../../components/typography/text.component";
// import {  Spacer } from "../../../../components/spacer/spacer.component";

import {
  TestimonialCard,
  TextCarouselWrap,
  ImageContainer,
  InfoSection,
  QuoteWrapper,
  PersonalInfoWrapper,
  Name,
  Hyphen,
  Position,
  HeaderText,
  TextContainer,
} from "./testimonials.styles";
import ScrollingDiv from "./Carousel";
import { generateID } from "../../../../infrastructure/lib/generateID";
// import { fontWeights } from "../../../../infrastructure/theme/fonts";
import { colors } from "../../../../infrastructure/theme/colors";
import firstImg from "../../../../assets/images/MaleCEOs1.jpg";
import SecondImg from "../../../../assets/images/femaleCEOs1.jpg";
import thirdImg from "../../../../assets/images/MaleCEOs2.jpg";
import fourthImg from "../../../../assets/images/femaleCEOs2.jpg";
import fifthImg from "../../../../assets/images/MaleCEOs3.jpg";

import {
  FaQuoteLeft as QuoteOpenIcon,
  FaQuoteRight as QuoteCloseIcon,
} from "react-icons/fa";

const Testimonials = () => {
  const cardData = [
    {
      number: "01",
      year: "2023",
      position: "CEO",
      Company: "Fintech Solutions",
      image: firstImg,
      quote:
        "Itechnology Terrain transformed our ideas into a seamless, user-friendly banking app. Their expertise in software development is unmatched, and their customer service is top-notch. We are thrilled with the results and highly recommend their services.",
      author: "John Adewale",
    },

    {
      number: "02",
      year: "2023",
      position: "CTO",
      Company: "EduTech Innovations",
      image: SecondImg,
      quote:
        " The training sessions provided by Itechnology Terrain were comprehensive and invaluable. Our team is now proficient in the latest technologies, thanks to their expert guidance. We look forward to future collaborations.",
      author: "Grace Nwankwo",
    },

    {
      number: "03",
      year: "2023",
      position: "Product Manager",
      Company: "HealthTech Corp",
      image: thirdImg,
      quote:
        " Collaborating with Itechnology Terrain was a game-changer for our project. Their proficiency in web development and commitment to excellence ensured the success of our platform. Their attention to detail and innovative approach were truly impressive.",
      author: "Michael Obi",
    },
    {
      number: "04",
      year: "2024",
      position: "Founder",
      Company: "StartupHub",
      image: fourthImg,
      quote:
        "Itechnology Terrain helped us develop a dynamic, scalable website that exceeded our expectations. Their professional approach and dedication to delivering quality work make them a standout in the industry. We couldn't be happier with the outcome.",
      author: "Chioma Okeke",
    },
    {
      number: "04",
      year: "2024",
      position: "Operations Manager",
      Company: "Green Energy Ltd",
      image: fifthImg,
      quote:
        "The custom software solutions provided by Itechnology Terrain have significantly streamlined our operations. Their team's technical skills and problem-solving capabilities are exceptional. We highly recommend their services to any company looking for reliable and efficient software solutions.",
      author: "David Eze",
    },
  ];
  return (
    <FlexibleDiv justifyContent="center" id="Testimonials">
      <FlexibleDiv paddingVertical="0" justifyContent="center">
        <Blurb width="70%">
          <h5>Testimonials</h5>
          <h3>What clients say about our work!</h3>
        </Blurb>
      </FlexibleDiv>
      <FlexibleDiv paddingVertical="0">
        <ScrollingDiv
          scrollCardBg="transparent"
          scrollOuterBg="transparent"
          autoScroll={false}
          scrollInnerWidth={"80%"}
        >
          {cardData.map(
            ({ year, position, Company, quote, author, image }, i) => (
              <TestimonialCard key={generateID(15)}>
                <TextCarouselWrap>
                  <ImageContainer>
                    <img
                      src={image || Image1}
                      alt="er"
                      width="100%"
                      height="100%"
                    />
                  </ImageContainer>
                  <InfoSection>
                    <QuoteWrapper>
                      <QuoteOpenIcon size="2rem" color={colors.secondary} />
                    </QuoteWrapper>
                    <PersonalInfoWrapper>
                      <Name>{author}</Name>
                      <Hyphen>-</Hyphen>
                      <Position>{position}</Position>
                    </PersonalInfoWrapper>
                    <HeaderText>Our Client's Amazing Review</HeaderText>
                    <TextContainer paddingVertical="0">
                      <Text
                        fontSize="15px"
                        className="cardText"
                        color={colors.white}
                        textAlign="left"
                      >
                        {quote}
                      </Text>
                    </TextContainer>

                    <QuoteWrapper justifyContent="flex-end">
                      <QuoteCloseIcon size="2rem" color={colors.secondary} />
                    </QuoteWrapper>
                  </InfoSection>
                </TextCarouselWrap>
              </TestimonialCard>
            )
          )}
        </ScrollingDiv>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export default Testimonials;
